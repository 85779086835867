

































import { Component, Vue, Ref } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import EntranceTeacherHistoryHeader from '@/components/organisms/EntranceTeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import PretestInformation from '@/components/organisms/PretestInformation.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import EntranceInfomeation from '@/components/organisms/EntranceInfomeation.vue'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectWide from '@/components/molecules/SelectWide.vue'
import ModalPretestInformation from '@/components/organisms/ModalPretestInformation.vue'
import { EntranceResult, EntranceInfo } from '@/models/api/history/entrance.ts'
import _ from 'lodash'

@Component({
  components: {
    StudentHistorySidebar,
    EntranceTeacherHistoryHeader,
    ColoredBox,
    SelectSubject,
    PretestInformation,
    TitleBase,
    TitleTextBase,
    EntranceInfomeation,
    TeacherHistoryBottomPanel,
    ButtonBase,
    SelectWide,
    ModalPretestInformation,
  },
})
export default class PretestSituation extends Vue {
  private userId = Vue.prototype.$cookies.get('dataGdls').student?.userId || 0

  @Ref() modalPretestInformation!: ModalPretestInformation

  private pretestResults: EntranceResult[] = []
  private entranceData: EntranceInfo = { school: '-', date: '-', countdown: '-' }

  private selectedSubject = 'all'
  private selectedCondition = ''

  private selectConditionOptions: { text: string; value: string }[] = [
    { text: '全条件', value: '' },
    { text: '全国高校入試問題', value: 'NP' },
    { text: '大問選択', value: 'NL' },
    { text: 'テーマ別', value: 'NT' },
  ]

  private async created(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.setResultData()
    await this.setEntranceData()
    Vue.prototype.$loading.complete()
  }

  private async onChangeSelected() {
    Vue.prototype.$loading.start()
    await this.setResultData()
    Vue.prototype.$loading.complete()
  }

  private showModal(id: number): void {
    this.modalPretestInformation.showModal(id, this.userId)
  }

  private async setResultData(): Promise<void> {
    const params: { userId: number; subjectCode?: string; searchType?: string } = { userId: this.userId }
    if (this.selectedSubject !== 'all') params.subjectCode = this.selectedSubject
    if (this.selectedCondition !== '') params.searchType = this.selectedCondition
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceResult`, {
        params: params,
      })
      .then((res: any) => {
        this.pretestResults = res.data
      })
  }

  private async setEntranceData(): Promise<void> {
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceInformation`, {
        params: { userId: this.userId },
      })
      .then((res: any) => {
        if (!_.isEmpty(res.data)) this.entranceData = res.data
      })
  }
}
